import { APIBase, makeQueryString } from '../util/Request';
import Config from '../Config';
import { OrderDetailModel } from 'src/model/OrderDetail.model';
import { OrderDetailModelDTO } from 'src/model/dto/OrderDetail.response';
import { OrderFilter } from 'src/context/Orders.context';
import { OrderListItemModel } from 'src/model/OrderListItem.model';
import { Builder } from 'builder-pattern';
import { OrderSearchByOption } from 'src/enum/orderSearchByOption.enum';
import { Utils } from '@care/web-ui';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const {
  CommonUtil: { omit },
} = Utils;

const URL = `${Config.API_URL}/orders`;

export const getOrderDetail = async (id: string): Promise<OrderDetailModel> => {
  const url = URL + '/' + id + '/admin';
  const res = await APIBase.get<OrderDetailModelDTO>(url);

  return OrderDetailModel.convertFromDTO(res.data);
};

export const getOrders = async (
  filter: OrderFilter,
  pageIndex: number,
  pageSize: number,
): Promise<GeneralListResponse<OrderListItemModel>> => {
  const { search, searchBy, fromDate, toDate } = filter;
  const isUniqueSearch =
    search && (searchBy === OrderSearchByOption.OrderId || searchBy === OrderSearchByOption.AppointmentId);
  const searchParam = searchBy === OrderSearchByOption.OrderId ? 'q' : searchBy;
  const params = {
    ...omit(filter, ['search', 'fromDate', 'toDate']),
    [searchParam]: search,
    pageIndex,
    pageSize,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    // Ignore date filter if search by order id
    fromDate: isUniqueSearch ? undefined : fromDate,
    toDate: isUniqueSearch ? undefined : toDate,
  };

  const url = `${URL}?${makeQueryString(params)}`;
  const res = await APIBase.get<GeneralListResponse<OrderListItemModel>>(url);
  const listData = res.data?.data.map((item) => Builder(OrderListItemModel, item).build()) || [];

  return { ...res.data, data: listData };
};
