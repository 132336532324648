import { Builder } from 'builder-pattern';
import Constants from '@care/web-ui/lib/constants';
import { DiscountType } from 'src/enum/DiscountType';
import { OrderStatus, OrderType } from 'src/enum/OrderStatus';
import { PaymentMethodType } from 'src/enum/PaymentMethod';
import { PaymentStatus } from 'src/enum/PaymentStatus';
import { Amount } from './Amount.model';
import { ConsumerModel } from './Consumer.model';
import { DeliveryAddressModel } from './DeliveryAddress.model';
import { OrderDetailModelDTO } from './dto/OrderDetail.response';
import { OrderItemModel } from './OrderItem.model';
import { OrganizationModel } from './OrganizationModel';
import { RefundModel } from './Refund.model';

const { defaultCurrency } = Constants.CurrencyConstant;

export class OrderDetailModel {
  id: string;
  orderNumber: string;
  status: OrderStatus;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethodType;
  createdAt: string;
  grandTotal: Amount;
  consumer: ConsumerModel;
  orderItems: OrderItemModel[];
  payment: any;
  refunds: RefundModel[];
  promoCode: string;
  discountAmount: number;
  discountType: DiscountType | null;
  organization: OrganizationModel;
  deliveryAddress?: DeliveryAddressModel;
  orderType: OrderType;

  static convertFromDTO(dto: OrderDetailModelDTO): OrderDetailModel {
    const { refunds, orderItems, consumer, grandTotal, organization, ...rest } = dto;
    const currency = (orderItems.length > 0 && orderItems[0].product.currency) || defaultCurrency;
    return Builder(OrderDetailModel, {
      ...rest,
      refunds: refunds?.map((item) => RefundModel.convertFromDTO(item, currency)) || [],
      consumer: Builder(ConsumerModel, { ...consumer }).build(),
      grandTotal: Builder(Amount, {
        value: grandTotal,
        currency: orderItems[0].product.currency,
      }).build(),
      orderItems: orderItems.map((item) => {
        const castItem = OrderItemModel.convertFromDTO(item);

        let discountValue = 0;
        if (dto.discountType === DiscountType.FixedAmount) {
          discountValue = dto.discountAmount;
        } else if (dto.discountType === DiscountType.Percent) {
          discountValue = (dto.discountAmount * castItem.price.value) / 100;
        }
        castItem.discountAmount = Builder(Amount, {
          currency: item.product.currency,
          value: discountValue,
        }).build();

        return castItem;
      }),
      organization: Builder(OrganizationModel, organization).build(),
    }).build();
  }
}
