import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from 'src/constant';
import OrderDetail from 'src/module/order-detail/OrderDetail';
import OrderDetailProvider from 'src/context/OrderDetail.context';
import OrdersProvider from 'src/context/Orders.context';
import Orders from 'src/module/orders/Orders';
import OrderActionProvider from 'src/context/OrderAction.context';
import ProductDetailProvider from 'src/context/ProductDetail.context';

function OrdersContainer() {
  return (
    <OrdersProvider>
      <Switch>
        <Route path={Routes.Orders} exact>
          <Orders />
        </Route>
        <Route path={`${Routes.Orders}/:id`} exact>
          <OrderDetailProvider>
            <OrderActionProvider>
              <ProductDetailProvider>
                <OrderDetail />
              </ProductDetailProvider>
            </OrderActionProvider>
          </OrderDetailProvider>
        </Route>
      </Switch>
    </OrdersProvider>
  );
}

export default OrdersContainer;
