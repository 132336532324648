import React, { useEffect } from 'react';
import { Breadcumb, Button, Row, Typography } from '@care/web-ui';
import { BreadcumbItem } from '@care/web-ui/lib/components/Breadcumb/Breadcumb';

import Spacer from 'src/component/Spacer/Spacer';
import { Routes } from 'src/constant';
import { useOrderDetailContext } from 'src/context/OrderDetail.context';
import { useHistory, useParams } from 'react-router';
import Divider from 'src/component/Divider/Divider';

import OrderDetailInformation from './order-detail-information/OrderDetailInformation';
import OrderCustomerDetails from '../refund-detail/order-customer-details/OrderCustomerDetails';
import OrderItemTable from '../refund-detail/order-item-table/OrderItemTable';
import OrderRefundsTable from './order-refunds-table/OrdeRefundsTable';
import RequestRefundActionButton from './request-refund-action-button/RequestRefundActionButton';
import OrderDeliveryAddress from './order-delivery-address/OrderDeliveryAddress';

import './OrderDetailStyle.scss';

const OrderDetail = () => {
  const query = useParams();
  const history = useHistory();

  const { order, loading, loadDetail } = useOrderDetailContext();
  const { id } = query as any;

  const breadcumbItems: BreadcumbItem[] = [
    { name: 'Orders', href: '/orders' },
    { name: order ? order.orderNumber : 'Order Number' },
  ];

  const orderItems = React.useMemo(
    () =>
      order?.orderItems?.map((item) => ({
        ...item,
        appointmentBookings: item.appointmentBookings?.map((booking) => ({
          ...booking,
          patientSnapshot: { ...booking.patient },
        })),
      })) || [],
    [order],
  );

  useEffect(() => {
    if (id) {
      loadDetail(id as string);
    }
  }, [id]);

  return (
    <div className="order-detail-container">
      <div className="top">
        <div className="header">
          <div>
            <Breadcumb breadcumbItems={breadcumbItems} />
            <Row spans={[16, 8]}>
              <Typography level={3} fontCategory="serif">
                {order ? order.orderNumber : ''}
              </Typography>
              <div className="buttons">
                <Button type="outline" title="Done" onClick={() => history.push(Routes.Orders)} />
                <RequestRefundActionButton />
              </div>
            </Row>
          </div>
        </div>
        {loading ? (
          <Typography level={6}>Loading...</Typography>
        ) : (
          <div className="body">
            {order && (
              <>
                <Row justify="space-between" gutter={80} spans={[12, 12]}>
                  <OrderDetailInformation orderDetail={order} />
                  <div>
                    <OrderCustomerDetails consumer={order.consumer} />
                    <Spacer size={20} />
                    <OrderDeliveryAddress detail={order.deliveryAddress} />
                  </div>
                </Row>

                <Spacer size={60} />
                <Typography level={5} weight="regular" fontCategory="serif">
                  Order Details
                </Typography>
                <Divider marginVertical={16} />
                <OrderItemTable orderItems={orderItems} orderType={order.orderType} />
                <Spacer size={20} />
                {order.refunds && order.refunds.length > 0 && (
                  <>
                    <Typography level={5} weight="regular" fontCategory="serif">
                      Refund Details
                    </Typography>
                    <Divider marginVertical={16} />
                    <OrderRefundsTable items={order.refunds} />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
