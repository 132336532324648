import React, { useMemo } from 'react';
import { Typography, Table } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { useOrdersContext } from 'src/context/Orders.context';
import { OrderSortBy } from 'src/enum/orderSortBy.enum';

import { getColumns } from './columns';

import './OrdersTableStyle.scss';
import { OrderType } from 'src/enum/OrderStatus';

const sortParams = {
  descend: OrderSortBy.Newest,
  ascend: OrderSortBy.Oldest,
};

export const OrdersTable = () => {
  const { data: orders, loading, pagination, setPagination, filter, setFilter } = useOrdersContext();

  const handleTableChange: React.ComponentProps<typeof Table>['onChange'] = (
    newPagination,
    filterTable,
    newSortBy: any,
  ) => {
    const { orderType: orderTypes } = filterTable;

    setPagination({
      ...pagination,
      pageIndex: newPagination.current,
      pageSize: newPagination.pageSize,
    });
    setFilter({
      ...filter,
      orderTypes: orderTypes as OrderType[],
      sortBy: sortParams[newSortBy.order],
    });
  };

  const { sortBy, orderTypes: orderTypesFilter } = filter;
  const columns = useMemo(() => getColumns({ sortBy, orderTypes: orderTypesFilter }), [sortBy, orderTypesFilter]);

  return (
    <div className="orders-table">
      <Table
        loading={loading}
        columns={columns as any[]}
        onChange={handleTableChange}
        data={orders}
        renderEmpty={
          <div>
            <img src="/refunds.png" style={{ width: 148 }} alt="orders" />
            <Spacer />
            <Typography fontCategory="serif" level={7}>
              There are no order yet
            </Typography>
          </div>
        }
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.REFUNDS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          current: pagination.pageIndex,
          pageSize: pagination.pageSize,
          onChange: (pageIndex: number, pageSize: number) => setPagination({ pageIndex, pageSize }),
        }}
      />
    </div>
  );
};
