import { Tag, CopyToClipboardInput, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import React from 'react';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import { paymentStatusTypeTag } from 'src/constant/paymentStatusTypeTag.constant';
import { orderStatusTypeTag, paymentMethodLabel } from 'src/constant/OrderStatusTag.constant';
import { OrderDetailModel } from 'src/model/OrderDetail.model';
import { PaymentMethodType } from 'src/enum/PaymentMethod';

const { formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

interface Props {
  orderDetail: OrderDetailModel;
}

const OrderDetailInformation = ({ orderDetail }: Props) => {
  console.log(orderDetail);
  const { status, paymentStatus, createdAt, paymentMethod, promoCode, organization, payment } = orderDetail;

  const orderStatusTag = orderStatusTypeTag[status];
  const paymentStatusTag = paymentStatusTypeTag[paymentStatus];

  return (
    <div>
      <Typography level={5} weight="regular" fontCategory="serif">
        Order Information
      </Typography>
      <Divider marginVertical={16} />
      <BlockInfo name="Organization" value={organization?.name} marginBottom={16} />
      <BlockInfo
        name="Order Status"
        value={<Tag type={orderStatusTag?.type} text={orderStatusTag?.label} />}
        marginBottom={16}
      />
      <BlockInfo name="Order Date & Time" value={formatDateString(createdAt, DATE.DATE_TIME)} marginBottom={16} />
      <BlockInfo
        name="Payment Status"
        value={<Tag type={paymentStatusTag?.type as any} text={paymentStatusTag?.label} />}
        marginBottom={16}
      />
      <BlockInfo
        name="Payment Method"
        value={
          <div>
            {paymentMethodLabel[paymentMethod]}
            &nbsp;&nbsp;&nbsp;
            {promoCode && <span>{`Promo Code: ${promoCode}`}</span>}
          </div>
        }
        marginBottom={22}
      />

      {(paymentMethod === PaymentMethodType.Card || paymentMethod === PaymentMethodType.PayNow) && (
        <BlockInfo
          name="Transaction ID"
          value={<CopyToClipboardInput value={payment?.providerPaymentId} size="small" />}
        />
      )}
    </div>
  );
};

export default OrderDetailInformation;
