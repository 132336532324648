import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from './constant';
import { SecureLayout } from './layout/SecureLayout';
import { init } from '@care/web-ui';
import { configsStore } from '@care/web-ui/lib/hooks';

import ConfigurationsContainer from './container/ConfigurationsContainer';
import OrganisationsContainer from './container/OrganisationsContainer';
import RefundsContainer from './container/RefundsContainer';
import PromoCodesContainer from './container/PromoCodesContainer';
import OrganizationListProvider from './context/OrganizationList.context';
import ProductContainer from './container/ProductContainer';
import OrdersContainer from './container/OrdersContainer';
import UsersContainer from './container/UsersContainer';
import FeedbacksContainer from './container/FeedbacksContainer';
import HealthCheckContainer from './container/HealthCheckContainer';

import './App.scss';

const { useFetchConfigs } = configsStore;

function App() {
  // Fetch app configs
  useFetchConfigs();
  useEffect(() => {
    // init care services
    init();
  }, []);

  return (
    <Router>
      <SecureLayout>
        <OrganizationListProvider autoLoad>
          <Switch>
            <Redirect path={Routes.Root} to={Routes.Refunds} exact />
            <Route path={Routes.Orders}>
              <OrdersContainer />
            </Route>
            <Route path={Routes.Users}>
              <UsersContainer />
            </Route>
            <Route path={Routes.Refunds}>
              <RefundsContainer />
            </Route>
            <Route path={Routes.PromoCodes}>
              <PromoCodesContainer />
            </Route>
            <Route path={Routes.Products}>
              <ProductContainer />
            </Route>
            <Route path={Routes.Organisations}>
              <OrganisationsContainer />
            </Route>
            <Route path={Routes.Configurations}>
              <ConfigurationsContainer />
            </Route>
            <Route path={Routes.Feedbacks}>
              <FeedbacksContainer />
            </Route>
          </Switch>
        </OrganizationListProvider>
      </SecureLayout>

      <Route path={Routes.HealthCheck}>
        <HealthCheckContainer />
      </Route>
    </Router>
  );
}

export default App;
