import React from 'react';
import { Icon, Table, Typography } from '@care/web-ui';
import { Amount } from 'src/model/Amount.model';
import { RefundAppointmentDTO, RefundOrderItemsDTO, RefundProductDTO } from 'src/model/dto/RefundDetail.response';
import ViewOrderItemApptTable from './ViewOrderItemAppointmentTable';
import { RefundOrderItemModel } from 'src/model/RefundOrderItem.model';
import { OrderType } from 'src/enum/OrderStatus';

type AppointmentItems = Pick<RefundAppointmentDTO, 'bookingNo' | 'startTime' | 'status' | 'patientSnapshot'>;

type OrderItems = Omit<RefundOrderItemModel, 'appointmentBookings'> & {
  appointmentBookings: AppointmentItems[];
};

interface Props {
  orderItems: OrderItems[];
  orderType?: OrderType;
}

const OrderItemTable = ({ orderItems, orderType }: Props) => {
  const columns = [
    {
      title: 'Order Line ID',
      dataIndex: 'orderItemNumber',
      key: 'orderItemNumber',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },
    {
      title: 'Recipient',
      dataIndex: 'appointmentBookings',
      key: 'recipient',
      render: (appointments: AppointmentItems[]) => {
        let patientSnapshot;
        for (const appt of appointments) {
          if (appt && appt.patientSnapshot) {
            patientSnapshot = appt.patientSnapshot;
            break;
          }
        }
        return <Typography level={9}>{patientSnapshot?.fullName}</Typography>;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity: string) => <Typography level={9}>{quantity}</Typography>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product',
      key: 'product',
      render: (value: RefundProductDTO) => <Typography level={9}>{value.name}</Typography>,
    },

    {
      title: 'Amount',
      dataIndex: 'price',
      key: 'price',
      render: ({ valueWithSymbol }: Amount) => <Typography level={9}>{valueWithSymbol}</Typography>,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={orderItems}
        expandable={{
          expandedRowRender: (data: RefundOrderItemsDTO) => {
            return <ViewOrderItemApptTable refundAppts={data.appointmentBookings} />;
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            orderType === OrderType.Product ? null : expanded ? (
              <Icon name="DownSmall" type="outline" onClick={() => onExpand(record, null)} />
            ) : (
              <Icon name="RightSmall" type="outline" onClick={() => onExpand(record, null)} />
            ),
        }}
      />
    </div>
  );
};

export default OrderItemTable;
