import React, { useEffect, useState, useCallback } from 'react';
import { Button, ComboBox, Input, Row } from '@care/web-ui';
import { OrderFilter, useOrdersContext } from 'src/context/Orders.context';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import Utils from '@care/web-ui/lib/utils';

import './OrdersTableToolbarStyle.scss';
import { OrderSearchByOption } from 'src/enum/orderSearchByOption.enum';
import { FilteredDate as EFilteredDate } from 'src/enum/filteredDate.enum';
import { filterDateOptions } from 'src/constant/date.constant';
import { FilteredDate } from 'src/component/filtered-date/FilteredDate';
import { convertDateFilterToDateRange } from 'src/util/convertFilteredDate.util';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';

const {
  DateUtil: { getPayloadDateString },
  CommonUtil: { debounce },
} = Utils;

const defaultFilter = {
  ...convertDateFilterToDateRange(EFilteredDate.Today),
  searchBy: OrderSearchByOption.OrderId,
};

const searchSelections: ComboBoxItem[] = [
  {
    text: 'Order ID',
    value: OrderSearchByOption.OrderId,
  },
  {
    text: 'Name',
    value: OrderSearchByOption.ConsumerName,
  },
  {
    text: 'Appointment ID',
    value: OrderSearchByOption.AppointmentId,
  },
  {
    text: 'ID no.',
    value: OrderSearchByOption.IdNumber,
  },
  {
    text: 'Passport no.',
    value: OrderSearchByOption.PassportNumber,
  },
  {
    text: 'Mobile no.',
    value: OrderSearchByOption.MobileNumber,
  },
];

export const OrdersTableToolbar = () => {
  const { loading, loadData, filter, setFilter, pagination, setPagination } = useOrdersContext();
  const [searchValue, setSearchValue] = useState('');
  const [filterDate, setFilterDate] = useState<EFilteredDate>(EFilteredDate.Today);

  const { searchBy } = filter;

  const handleChangeDate = (newFilterDate: EFilteredDate): void => {
    const { fromDate, toDate } = convertDateFilterToDateRange(newFilterDate);
    const filteredQuery: OrderFilter = {
      ...filter,
      fromDate: fromDate && getPayloadDateString(fromDate),
      toDate: toDate && getPayloadDateString(toDate, true),
    };
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
    setFilter(filteredQuery);
    setFilterDate(newFilterDate);
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, search: value });
    }, 1000),
    [filter],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  const reset = () => {
    setFilter({ ...defaultFilter });
    setSearchValue('');
    setFilterDate(EFilteredDate.Today);
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
  };

  const onSearchByChanged = (value: OrderSearchByOption) => {
    setFilter({ ...filter, searchBy: value });
  };

  useEffect(() => {
    loadData();
  }, [filter, pagination.pageIndex, pagination.pageSize]);

  return (
    <div className="order-table-toolbar">
      <Row gutter={16} spans={[6, 7, 7, 3]}>
        <ComboBox items={searchSelections} value={searchBy} disabled={loading} onChange={onSearchByChanged} />
        <Input
          iconRight={{ name: 'Search' }}
          placeholder="Search"
          disabled={loading}
          value={searchValue}
          onChange={handleSearch}
        />
        <FilteredDate filterDate={filterDate} onChangeDate={handleChangeDate} options={filterDateOptions} />
        <Button loading={loading} type="secondary" title="Reset" onClick={reset} />
      </Row>
    </div>
  );
};
